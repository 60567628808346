import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import 'assets/styles/app.scss';

import Card from '@moonshineragency/ui/src/components/Card/Card';
import Form, {
  Field,
  FormNavigation,
  CheckboxGroup,
  RadioGroup,
} from '@moonshineragency/ui/src/components/Forms/Forms';

import Container from 'components/Container/Container';
import Steps, { Step } from '@moonshineragency/ui/src/components/Steps/Steps';
import Layout from 'components/Layout/Layout';

const Example1Page = () => {
  const [width, setWidth] = useState(0);

  const resizeHandler = () => {
    setWidth(document.body.getBoundingClientRect().width);
  };

  useEffect(() => {
    setWidth(document.body.getBoundingClientRect().width);
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const verticalSteps = width >= 980;
  const verticalClassNames = 'p-3 w-20 mr-3 mt-4 h-50';
  const stepsClassNames = `${verticalSteps ? verticalClassNames : ''}`;

  return (
    <Layout title="Stromanschluss ändern">
      <Container className="py-3">
        <div className="d-lg-flex">
          <div className={`sticky-top ${stepsClassNames}`}>
            <Steps
              className="py-2"
              orientation={(verticalSteps && 'vertical') || 'horizontal'}
              active="customer"
            >
              <Step id="customer" title="Kunde" icon="user" />
              <Step id="location" title="Standort" icon="home" />
              <Step id="connection" title="Anschluss" icon="units" />
              <Step id="electrician" title="Elektriker" icon="wrench" />
              <Step id="check" title="Prüfen" icon="check" />
            </Steps>
          </div>
          <Form
            className="mt-4 flex-basis-100"
            initialValues={{ email: '' }}
            onValidate={values => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Bitte geben Sie Ihre E-Mail ein';
              }

              if (Object.keys(errors).length) {
                throw errors;
              }
            }}
            onSubmit={() => navigate('/consumer/example2')}
          >
            <Card className="mb-3">
              <Field
                name="email"
                type="email"
                hint="Bitte achten Sie auf die korrekte Schreibweise, wir senden Ihre Auftragsbestätigung an diese Adresse."
                label="Ihre E-Mailadresse"
              />
            </Card>
            <div className="mb-3">
              <RadioGroup
                labelClassName="font-weight-normal"
                inline
                items={[
                  {
                    name: 'privat',
                    label: 'Privatkunde',
                  },
                  {
                    name: 'geschaeft',
                    label: 'Geschäftskunde',
                  },
                ]}
                name="kundentyp"
              />
            </div>
            <Card className="mb-4">
              <div>
                <Field
                  className="mb-3"
                  name="kundennummer"
                  placeholder="Falls vorhanden"
                  type="text"
                  hint="Sie finden Ihre Kundennummer auf den Rechnungen der Netz NÖ, z.B. 10011234"
                  label="Kundennummer"
                />
              </div>
              <div className="mb-3">
                <RadioGroup
                  inline
                  labelClassName="font-weight-normal"
                  label="Anrede"
                  items={[
                    {
                      name: 'frau',
                      label: 'Frau',
                    },
                    {
                      name: 'herr',
                      label: 'Herr',
                    },
                    {
                      name: 'divers',
                      label: 'Divers',
                    },
                  ]}
                  name="anrede"
                />
              </div>
              <div className="mb-3">
                <Field
                  name="vorname"
                  type="text"
                  label="Vorname"
                  className="mb-3"
                />
              </div>
              <div className="mb-3">
                <Field
                  name="nachname"
                  type="text"
                  label="Nachname"
                  className="mb-3"
                />
              </div>
              <div className="mb-3">
                <Field
                  name="adresse"
                  type="text"
                  label="Adresse"
                  className="mb-3"
                />
              </div>
              <div className="mb-3">
                <Field
                  className="mb-3"
                  name="telefon"
                  type="text"
                  label="Handy- oder Festnetznummer"
                />
              </div>
              <div>
                <CheckboxGroup
                  labelClassName="font-weight-normal"
                  name="delivery"
                  items={[
                    {
                      label: 'Abweichender Rechnungsempfänger',
                      name: 'yes',
                    },
                  ]}
                />
              </div>
            </Card>
            <FormNavigation back="/consumer/" />
          </Form>
        </div>
      </Container>
    </Layout>
  );
};

export default Example1Page;
